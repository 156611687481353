
  import React from 'react';
  import COMPONENTS from '../../components';
  import { get } from 'lodash';
  import PageLayout from '../../layout';
  import { graphql } from 'gatsby';

  class Page extends React.Component {
    state = {
      data: [],
    };

    componentDidMount() {
      let slices;

      const data = get(this, 'props.data.startupsJson');

      if (data) {
        slices = Object.entries(data).map(([key, content]) => ({
          componentName: key,
          ...content,
        }));

        slices.sort(
          ({ position: positionA }, { position: positionB }) =>
            positionA - positionB
        );

        this.setState({
          data: slices,
        });
      }
    }

    render() {
      return (
        <PageLayout>
          {get(this, 'state.data').map(({ componentName, data }, index) => {
            const ComponentType = COMPONENTS[componentName];
            return <ComponentType key={index} {...data} />;
          })}
        </PageLayout>
      );
    }
  }

  export default Page;

  export const pageQuery = graphql`
    query {
      startupsJson {
        
      
      
      
      
      
      
      
      
      
      
      
      
      
      CTAWith2Buttons0 {
        position
        componentName
        data {
           title description buttonDark {  text link } buttonLight {  text link }
        }
      }
    
      BannerMask1 {
        position
        componentName
        data {
           media {  type src } corners height
        }
      }
    
      ProductSingle2 {
        position
        componentName
        data {
           title textBody image
        }
      }
    
      Block3Column3 {
        position
        componentName
        data {
           background content {  title textBody }
        }
      }
    
      CTA4 {
        position
        componentName
        data {
           title textBody button {  text link }
        }
      }
    
      Divider5 {
        position
        componentName
        data {
           height
        }
      }
    
      GridPeople6 {
        position
        componentName
        data {
           title subtitle people {  name imageUrl description }
        }
      }
    
      BannerMask7 {
        position
        componentName
        data {
           media {  type src } corners height
        }
      }
    
      ProductSingle8 {
        position
        componentName
        data {
           title textBody image
        }
      }
    
      LogoRoll9 {
        position
        componentName
        data {
           header iconGroup {  social }
        }
      }
    
      CTAPhoto10 {
        position
        componentName
        data {
           image title textBody button {  text link }
        }
      }
    
      Divider11 {
        position
        componentName
        data {
           height
        }
      }
    
      CTAColumn12 {
        position
        componentName
        data {
           title type description button {  text link }
        }
      }
    
      }
    }
  `